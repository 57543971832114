import React, { ChangeEvent, useContext, useEffect, useReducer, useState } from 'react';

// Third-party library imports
import { useLazyQuery } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateValidationError } from '@mui/x-date-pickers/models';
import dayjs, { Dayjs } from 'dayjs';
// MUI core imports
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { AlertCircle } from 'lucide-react';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { usePharmacyField } from '../../hooks/usePharmacyField';
import VaccinationAID, { VaccinationScreeningInput } from '../vaccine/VaccinationAID'; // Adjust the import path as needed
import { logger } from '../../utils/logger';
// Local imports
import AuthContext from '../../contexts/AuthContext';
import { GET_VACCINE_DATA } from '../../graphql-queries';
import VaccineRecordForm from './PatientMultipleVaccination'; // Adjust the import path as necessary
import PatientVaccinationsDueList from './PatientVaccinationsDueList';
import { GraphQLVaxListResponse } from './Vaccine';
import countryCodes from './common/CountryCodes';
import { IdentifyIndividualResponse } from './identifyIndividual';
import { PatientItem } from './patient';
import { AIRRecordEncounterResponse, VaxHistoryResponse } from './vaxHistory';
import {
  NipvipClaimPayload,
  submitcovidVaxClaims,
  submitNipvipClaim,
  NipvipClaimResponse,
  CVCPClaimResponse,
  UserDetails,
  CVCPClaimPayload,
} from '../../helpers/api/7CPA/apiUtilsPPA';
import { initialSubmissionState, submissionReducer } from '../../redux/vax/reducers';
import { useSearchParams } from 'react-router-dom';
import { useIdToken } from '../../hooks/useIdToken';
import PatientDetailsModal from '../../components/PatientDetailsModal';
import { useVaccineData } from '../../contexts/VaccineDataContext';
import SubmissionStatusModal from '../../components/SubmissionStatusModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PatientVaccinationHistory from './PatientVaccinationHistory';

interface Episode {
  id: number;
  vaccineBatch?: string;
  vaccineCode?: string;
  vaccineDose?: string; // Adjust types as necessary
  vaccineSerialNumber?: string;
  vaccineFundingType?: string;
  routeOfAdministration?: string;
  // Add more fields as necessary
}
interface PatientVaccinationProps {
  patientIdentifier: string;
  vaxHistory: VaxHistoryResponse | null;
  individualDetails: IdentifyIndividualResponse | null;
  patientItemDetails: PatientItem | undefined;
  formState: VaccinationFormState | null;
  setFormState: (state: VaccinationFormState) => void;
}

interface VaccineOption {
  label: string; // This will store the VaccineBrandName
  value: string; // This will store the VaccineCode
  isEligibleForNIP?: boolean; // Add this line
}
interface FormValue {
  immunisationDate: Dayjs | null; // Adjusted to match expected type, assuming Dayjs is used
  infectiousDisease: string;
  vaccine: string;
  vaccineBrandName: string; // Add this line
  routeOfAdministration: string;
  siteOfAdministration: string;
  dose: string;
  batchNumber: string;
  expiryDate: string;
  doseNumber: string;
  vaccineFundingType: string;
  comments: string;
  idealNextDoseDate: string;
  cmiProvided: boolean;
  deemedEligible: boolean;
  VaccineSerialNumber: string;
  countryCode: string;
  SchoolId: string;
  errorInfo?: any; // Define the type more specifically according to what errorInfo contains
  otherProviderNumber: string;
  isOverseas: boolean;
  filteredVaccines: VaccineOption[]; // Add this line
  otherDiseases: VaccineOption[]; // Add this line
  isOtherProvider: boolean;
  status: string;
  nipClaim: boolean; // Added this line
  // Structure reflecting your form values
}

const initialFormState = {
  claimId: '',
  immunisationDate: '',
  infectiousDisease: '',
  vaccineBrandName: '',
  vaccine: '',
  routeOfAdministration: '',
  siteOfAdministration: '',
  dose: '',
  batchNumber: '',
  expiryDate: '',
  doseNumber: '1',
  vaccineFundingType: '',
  comments: '',
  idealNextDoseDate: '',
  cmiProvided: false,
  deemedEligible: false,
  VaccineSerialNumber: '',
  countryCode: '',
  SchoolId: '',
  otherProviderNumber: '',
  OtherHpioNumber: '',
  OtherHpiiNumber: '',
  isOverseas: false,
  nipClaim: false,
};

// Define the shape of the form state
export interface VaccinationFormState {
  immunisationDate: Dayjs | null;
  formValues: any; // Replace 'any' with a more specific type if possible
  expiryDate: Dayjs | null;
  isNipClaim: boolean;
  medicareDVANumber: string;
  medicareIRN: string;
  isOverseas: boolean;
  isOtherProvider: boolean;
  vaccinationAssessmentAnswers: VaccinationScreeningInput | null;
}

type FormValues = {
  immunisationDate: string;
  infectiousDisease: string;
  vaccine: string;
  vaccineBrandName: string;
  routeOfAdministration: string;
  siteOfAdministration: string;
  dose: string;
  batchNumber: string;
  expiryDate: string;
  doseNumber: string;
  vaccineFundingType: string;
  comments: string;
  idealNextDoseDate: string;
  cmiProvided: boolean;
  deemedEligible: boolean;
  VaccineSerialNumber: string;
  countryCode: string;
  SchoolId: string;
  otherProviderNumber: string;
  OtherHpioNumber: string;
  OtherHpiiNumber: string;
  isOverseas: boolean;
  nipClaim: boolean;
  [key: string]: any;
};

const PatientVaccination: React.FC<PatientVaccinationProps> = ({
  patientIdentifier,
  vaxHistory,
  individualDetails,
  patientItemDetails,
  formState,
  setFormState,
}) => {
  const { vaccineData, loading, error } = useVaccineData();
  const [individualIdentifierDetails, setIndividualIdentifierDetails] = useState<IdentifyIndividualResponse>();
  const theme = useTheme();
  const { idToken, isLoading, error: idTokenError } = useIdToken();
  const [transactionUUID, setTransactionUUID] = useState<string>(uuidv4());
  const [forms, setForms] = useState<FormValue[]>([{} as FormValue]); // Initialize with one empty form
  const addForm = () => {
    setForms([...forms, {} as FormValue]);
    const newForms = [...forms, {} as FormValue];

    // logger.log(newForms); // Example for debugging
  };

  useEffect(() => {
    if (forms.length >= 1) {
      logger.log(forms); // Ensure forms[1] exists
    }
  }, [forms]); // This useEffect depends on `forms` and will run every time `forms` changes.

  const removeForm = (index: number) => {
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };
  const [isAIDOpen, setIsAIDOpen] = useState(false);
  const handleAIDOpen = () => setIsAIDOpen(true);
  const handleAIDClose = () => setIsAIDOpen(false);
  const [submittedPatientDetails, setSubmittedPatientDetails] = useState<any>(null);

  // Initialize state with formState if available, otherwise use default values
  const [immunisationDate, setImmunisationDate] = useState<Dayjs | null>(formState?.immunisationDate || dayjs());
  const [formValues, setFormValues] = useState<FormValues>(formState?.formValues || initialFormState);
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(formState?.expiryDate || null);
  const [isNipClaim, setIsNipClaim] = useState(formState?.isNipClaim || false);
  const [medicareDVANumber, setMedicareDVANumber] = useState(formState?.medicareDVANumber || '');
  const [medicareIRN, setMedicareIRN] = useState(formState?.medicareIRN || '');
  const [isOverseas, setIsOverseas] = useState(formState?.isOverseas || false);
  const [isOtherProvider, setIsOtherProvider] = useState(formState?.isOtherProvider || false);
  const [vaccinationAssessmentAnswers, setVaccinationAssessmentAnswers] = useState<VaccinationScreeningInput | null>(
    formState?.vaccinationAssessmentAnswers || null
  );

  const [showDiseaseDropdown, setShowDiseaseDropdown] = useState(false);
  const [otherDiseases, setOtherDiseases] = useState<VaccineOption[]>([]);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitted: false,
    success: false,
    message: '',
  });
  const [modalState, setModalState] = useState({
    open: false,
    loading: false,
    success: false,
    message: '',
  });
  const [ppaSubmissionStatus, setPpaSubmissionStatus] = useState({
    submitted: false,
    success: false,
    message: '',
    loading: false,
  });
  const [airSubmissionStatus, setAirSubmissionStatus] = useState({
    submitted: false,
    success: false,
    message: '',
    loading: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [showPatientDetailsModal, setShowPatientDetailsModal] = useState(false);

  const {
    providerNumber,
    providerStore,
    hpiiNumber,
    hpioNumber,
    minorId,
    deviceName,
    pharmacyID,
    providerAHPRA,
    providerFirstName,
    providerLastName,
    prodaOrgId,
  } = useContext(AuthContext);

  const calculateAge = (birthdate: string | undefined): number => {
    if (!birthdate) return 0; // Return 0 or handle undefined birthdate appropriately

    const birthdateDayjs = dayjs(birthdate, 'DDMMYYYY');
    const now = dayjs();
    return now.diff(birthdateDayjs, 'year');
  };

  const userId = usePharmacyField('userId');
  const userKey = usePharmacyField('userKey');
  const CVCP = usePharmacyField('CVCP');
  const NIPVIP = usePharmacyField('NIPVIP');
  const pharmacyLoading = usePharmacyField('loading');
  const pharmacyError = usePharmacyField('error');
  const convertDateFormat = (dateString: string) => {
    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);
    return `${year}-${month}-${day}`;
  };

  // fetch the values from GraphQL API
  const [submissionState, dispatchSubmission] = useReducer(submissionReducer, initialSubmissionState);

  const [isBirthDose, setIsBirthDose] = useState(false);
  const [isMultipleVaccines, setIsMultipleVaccines] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [acceptAndConfirm, setAcceptAndConfirm] = useState('N');
  const [claimId, setClaimId] = useState('');
  const [otherProviderNumber, setOtherProviderNumber] = useState('');
  const [patientDOBForPPA, setPatientDOBForPPA] = useState('');

  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [wasPpaSubmissionAttempted, setWasPpaSubmissionAttempted] = useState(false);

  // Fetch diseases when the component mounts
  useEffect(() => {
    const loadedIndividualIdentiferDetails = localStorage.getItem(patientIdentifier);
    if (loadedIndividualIdentiferDetails) {
      setIndividualIdentifierDetails(JSON.parse(loadedIndividualIdentiferDetails));
    } else {
      // Handle the case where no details are found, if necessary
      logger.log('No patient details found for the provided identifier.');
    }

    const age = calculateAge(patientItemDetails?.dateOfBirth.split('-').reverse().join(''));

    logger.log('Age:', age);
  }, [patientIdentifier, patientItemDetails?.dateOfBirth, isBirthDose]);

  // Handle change for form fields
  const handleBirthDoseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBirthDose(event.target.checked);
  };

  const handleMultipleVaccines = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMultipleVaccines(event.target.checked);
  };

  const handleOtherDiseaseChange = (event: SelectChangeEvent<string>) => {
    const selectedDisease = event.target.value as string;
    setFormValues((prevValues: FormValues) => ({
      ...prevValues,
      infectiousDisease: selectedDisease,
    }));
    // Ensure the correct disease is being passed to the filter function
    filterVaccines(selectedDisease);
  };

  const filterVaccines = (disease: string) => {
    if (vaccineData && vaccineData.listVaxDataAIRS && vaccineData.listVaxDataAIRS.items) {
      const vaccinesForDisease = vaccineData.listVaxDataAIRS.items.filter(
        (item) => item.DiseaseAntigenName === disease
      );
      const vaccineOptions: VaccineOption[] = vaccinesForDisease
        .map((vaccine) => ({
          label: vaccine.VaccineBrandName,
          value: vaccine.VaccineCode,
          isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Change the type to boolean
        }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Add this line to sort alphabetically
      setFilteredVaccines(vaccineOptions);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'dose' && !/^\d*$/.test(value)) {
      return; // Don't update if it's not a valid number
    }

    setFormValues((prevValues: FormValues) => {
      const newFormValues = {
        ...prevValues,
        [name]: value,
      };

      // Update the parent state
      setFormState({
        immunisationDate,
        formValues: newFormValues,
        expiryDate,
        isNipClaim,
        medicareDVANumber,
        medicareIRN,
        isOverseas,
        isOtherProvider,
        vaccinationAssessmentAnswers,
      });

      return newFormValues;
    });

    // Reset error when vaccineFundingType is not NIP
    if (name === 'vaccineFundingType') {
      if (value === 'NIP') {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          batchNumber: !formValues.batchNumber ? 'Batch Number is required for NIP funded vaccines' : null,
          medicareNumber: !medicareDVANumber ? 'Medicare Number is required for NIP funded vaccines' : null,
          medicareIRN: !medicareIRN ? 'Medicare IRN is required for NIP funded vaccines' : null,
        }));
        setIsNipClaim(true);
        setFormValues((prevValues: FormValues) => ({
          ...prevValues,
          nipClaim: true,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          batchNumber: null,
          medicareNumber: null,
          medicareIRN: null,
        }));
        setIsNipClaim(false);
        setFormValues((prevValues: FormValues) => ({
          ...prevValues,
          nipClaim: false,
        }));
      }
    }

    // Real-time validation for Batch Number when vaccineFundingType is NIP
    if (name === 'vaccineFundingType' && value === 'NIP' && !formValues.batchNumber && !value) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        batchNumber: 'Batch Number is required for NIP funded vaccines',
      }));
    } else if (name === 'batchNumber') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        batchNumber: null,
      }));
    }

    // Validation for Vaccine Dose
    if (name === 'dose' && value.trim() === '') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dose: 'Vaccine Dose is required',
      }));
    } else if (name === 'dose') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dose: null,
      }));
    }
    if (name === 'vaccineFundingType' && value === 'NIP') {
      setIsNipClaim(true);
      setFormValues((prevValues: FormValues) => ({
        ...prevValues,
        nipClaim: true,
      }));
    } else if (name === 'vaccineFundingType') {
      setIsNipClaim(false);
      setFormValues((prevValues: FormValues) => ({
        ...prevValues,
        nipClaim: false,
      }));
    }
  };

  const handleVaccineChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    // Find the selected option based on the value
    const selectedOption = filteredVaccines.find((vaccine) => vaccine.value === value);
    const label = selectedOption ? selectedOption.label : '';
    logger.log('🚀 ~ handleChange ~ label:', label);

    setFormValues((prevValues: FormValues) => {
      const newFormValues = {
        ...prevValues,
        [name]: value,
        vaccineBrandName: label,
      };

      // Update the parent state
      setFormState({
        immunisationDate,
        formValues: newFormValues,
        expiryDate,
        isNipClaim,
        medicareDVANumber,
        medicareIRN,
        isOverseas,
        isOtherProvider,
        vaccinationAssessmentAnswers,
      });

      return newFormValues;
    });

    // Validation for Vaccine Dose
    if (name === 'dose' && value.trim() === '') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dose: 'Vaccine Dose is required',
      }));
    } else if (name === 'dose') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dose: null,
      }));
    }
    if (name === 'vaccineFundingType' && value === 'NIP') {
      setIsNipClaim(true);
      setFormValues((prevValues: FormValues) => ({
        ...prevValues,
        nipClaim: true,
      }));
    } else if (name === 'vaccineFundingType') {
      setIsNipClaim(false);
      setFormValues((prevValues: FormValues) => ({
        ...prevValues,
        nipClaim: false,
      }));
    }
  };

  useEffect(() => {
    logger.log('Updated Form Values:', formValues);
  }, [formValues]);
  const handleFormChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedForms = forms.map((form, i) =>
      i === index ? { ...form, [event.target.name]: event.target.value } : form
    );
    setForms(updatedForms);
  };
  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues((prevValues: FormValues) => {
      const newFormValues = {
        ...prevValues,
        [name]: checked,
      };

      // Update the parent state
      setFormState({
        immunisationDate,
        formValues: newFormValues,
        expiryDate,
        isNipClaim: name === 'nipClaim' ? checked : isNipClaim,
        medicareDVANumber,
        medicareIRN,
        isOverseas,
        isOtherProvider,
        vaccinationAssessmentAnswers,
      });

      return newFormValues;
    });
    if (name === 'nipClaim') {
      setIsNipClaim(checked);
    }
  };
  const [filteredVaccines, setFilteredVaccines] = useState<VaccineOption[]>([]);

  const handleDiseaseSelection = (disease: string) => {
    setShowDiseaseDropdown(false); // Always hide the dropdown initially
    setFormValues({ ...formValues, infectiousDisease: disease }); // Update the disease selection

    if (disease === 'Others') {
      // Logic for "Others" remains the same
      logger.log('Show dropdown for other diseases');
      setShowDiseaseDropdown(true);
      setFilteredVaccines([]);
      if (vaccineData && vaccineData.listVaxDataAIRS && vaccineData.listVaxDataAIRS.items) {
        const otherDiseasesOptions = vaccineData.listVaxDataAIRS.items
          .filter((item) => item.DiseaseAntigenName !== 'COVID-19' && item.DiseaseAntigenName !== 'Influenza')
          .map((vaccine) => ({
            label: vaccine.DiseaseAntigenName,
            value: vaccine.DiseaseAntigenName,
            isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Ensure this information is included
          }))
          .filter((value, index, self) => self.findIndex((v) => v.label === value.label) === index)
          .sort((a, b) => a.label.localeCompare(b.label)); // Add this line to sort alphabetically
        setOtherDiseases(otherDiseasesOptions);
      }
    } else {
      // Reset otherDiseases to ensure dropdown is not populated when not needed
      setOtherDiseases([]);
      if (vaccineData && vaccineData.listVaxDataAIRS && vaccineData.listVaxDataAIRS.items) {
        let filteredVaccines = vaccineData.listVaxDataAIRS.items
          .filter((item) => item.DiseaseAntigenName === disease)
          .map((vaccine) => ({
            label: vaccine.VaccineBrandName,
            value: vaccine.VaccineCode,
            isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Ensure this information is included
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setFilteredVaccines(filteredVaccines);
      }
    }
  };

  // State for dynamically calculated dose (if needed)
  // const [calculatedDose, setCalculatedDose] = useState("");

  // Mock data for dropdowns
  const diseases = ['COVID-19', 'Influenza', 'Others'];
  const routeOptions = [
    { label: 'Oral', code: 'PO' },
    { label: 'Subcutaneous', code: 'SC' },
    { label: 'Intradermal', code: 'ID' },
    { label: 'Intramuscular', code: 'IM' },
  ];
  const siteOptions = [
    { label: 'Left Arm', code: 'LA' },
    { label: 'Right Arm', code: 'RA' },
    { label: 'Left Thigh', code: 'LT' },
    { label: 'Right Thigh', code: 'RT' },
  ];

  const vaccineFundingTypes = [
    { label: 'Antenatal', code: 'AEN' },
    { label: 'NIP/Commonwealth', code: 'NIP' },
    { label: 'Private', code: 'PRV' },
    { label: 'State Program', code: 'STP' },
  ];

  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17'));
  const [formErrors, setFormErrors] = useState<{
    dose: string | null;
    batchNumber: string | null;
    medicareNumber: string | null;
    medicareIRN: string | null;
  }>({
    dose: null,
    batchNumber: null,
    medicareNumber: null,
    medicareIRN: null,
  });

  const handleOverseasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOverseas(event.target.checked);
  };

  const hanldeOtherProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOtherProvider(event.target.checked);
  };

  function handleApiResponse(response: AIRRecordEncounterResponse, isAIR: boolean = true): boolean {
    let formErrors: any = {}; // Object to store errors by episode ID

    // Handle specific success case for AIR-W-1008
    if (response.success && response.data.statusCode === 'AIR-W-1008') {
      if (response.data.claimDetails && response.data.claimDetails.claimId) {
        setClaimId(response.data.claimDetails.claimId);
      }
    }

    // Handle success with a confirmation dialog for specific code
    if (response.success && response.data.codeType?.startsWith('AIRWBU') && response.data.statusCode === 'AIR-W-1004') {
      setShowConfirmDialog(true);
      setModalState({ ...modalState, open: false }); // Close any existing modals
      return true; // Execution should pause here until user interaction
    }

    // Handle general success scenarios
    if (
      response.success &&
      !response.data.codeType?.startsWith('AIRWBU') &&
      !response.data.codeType?.startsWith('AIREBU')
    ) {
      const successMessage = isAIR ? 'Vaccination record submitted successfully' : 'NIP Claim submitted successfully';
      const submissionStatus = {
        submitted: true,
        success: true,
        message: successMessage,
      };
      setSubmissionStatus(submissionStatus);
      setModalState({
        ...modalState,
        open: true,
        loading: false,
        ...submissionStatus,
      });
      return true;
    }

    // Handle success but with errors present (errors should be an array)
    if (response.success && Array.isArray(response.data.errors) && response.data.errors.length > 0) {
      let invalidMessages: string[] = [];
      logger.log(response.data.message || 'Response contains errors.');

      // Process each error
      response.data.errors.forEach((error: any, index: number) => {
        const formId = index + 1; // Adjust index if necessary
        formErrors[formId] = error.message;
        invalidMessages.push(`${error.code}: ${error.message}`);
      });

      // Update forms with the errors
      const updatedForms = forms.map((form, index) => ({
        ...form,
        errorInfo: formErrors[index + 1] || null, // Error message if it exists
      }));
      setForms(updatedForms);

      // Compile error messages and update state
      const errorMessages = invalidMessages.join(', ');
      const submissionStatus = {
        submitted: true,
        success: false,
        message: errorMessages,
      };
      setSubmissionStatus(submissionStatus);
      setModalState({
        ...modalState,
        open: true,
        loading: false,
        ...submissionStatus,
      });
      return false;
    }

    // Handle case with warnings or invalid episodes, and AIREBU/AIRWBU code types
    if (
      response.success &&
      (response.data.codeType?.startsWith('AIRWBU') || response.data.codeType?.startsWith('AIREBU'))
    ) {
      logger.log('Success with warnings or invalid episodes.');
      let invalidMessages: string[] = [];

      // Process each encounter, ensuring encounters and episodes are handled safely
      response.data.claimDetails?.encounters?.forEach((encounter) => {
        if (encounter.information?.status === 'INVALID' || encounter.information?.status === 'WARNING') {
          invalidMessages.push(`${encounter.information.code}: ${encounter.information.text}`);
        }

        // Process episodes within each encounter, if episodes are available
        if (Array.isArray(encounter.episodes)) {
          encounter.episodes.forEach((episode) => {
            if (episode && episode.information?.status === 'INVALID' && episode.information.text) {
              formErrors[episode.id] = episode.information.text;
              invalidMessages.push(`${encounter.information.code}: ${episode.information.text}`);
            }
          });
        }
      });

      // Update forms with error information
      const updatedForms = forms.map((form, index) => ({
        ...form,
        errorInfo: formErrors[index + 1] || null,
      }));
      setForms(updatedForms);

      // Compile and log messages, then update state
      const combinedMessage = invalidMessages.join(', ');
      logger.log(combinedMessage);
      const submissionStatus = {
        submitted: true,
        success: false,
        message: combinedMessage,
      };
      setSubmissionStatus(submissionStatus);
      setModalState({
        ...modalState,
        open: true,
        loading: false,
        ...submissionStatus,
      });
      return false;
    }

    // Handle any other unexpected cases
    const errorMessage = 'Unexpected response from AIR';
    const genericSubmissionStatus = {
      submitted: true,
      success: false,
      message: errorMessage,
      loading: false, // Add the missing loading property
    };

    // Use the correct submission status and modal update based on the type
    if (isAIR) {
      setSubmissionStatus(genericSubmissionStatus);
    } else {
      setPpaSubmissionStatus({
        ...genericSubmissionStatus,
        message: 'An unexpected error occurred',
      });
    }
    setModalState({
      ...modalState,
      open: true,
      ...genericSubmissionStatus,
      loading: false, // Add the missing loading property
    });

    return false;
  }

  function setErrorState(isAIR: boolean, message: string) {
    if (isAIR) {
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: message,
      });
    } else {
      setPpaSubmissionStatus({
        submitted: true,
        success: false,
        message: message,
        loading: false,
      });
    }
    setModalState({
      ...modalState,
      open: true,
      loading: false,
      success: false,
      message: message,
    });
  }

  const isSubmitButtonDisabled = (): boolean => {
    return (
      formValues.dose.trim() === '' ||
      formErrors.dose !== null ||
      !formValues.cmiProvided ||
      !formValues.deemedEligible ||
      (needsMedicareDetails() &&
        (medicareDVANumber.trim() === '' ||
          medicareIRN.trim() === '' ||
          formErrors.medicareNumber !== null ||
          formErrors.medicareIRN !== null ||
          medicareDVANumber.length !== 10 ||
          !validateMedicareNumber(medicareDVANumber) ||
          !validateMedicareIRN(medicareIRN)))
    );
  };

  useEffect(() => {
    if (expiryDate === null) {
      setFormValues((prevValues: FormValues) => ({
        ...prevValues,
        expiryDate: '',
      }));
    }
  }, [expiryDate]);

  // Form submission
  const handleSubmit = async (event: React.FormEvent) => {
    let ppaSuccess = true; // Initialize as true, will be set to false if PPA submission fails
    event.preventDefault();

    if (!idToken) {
      console.error('No authentication token available');
      // Handle this error state, perhaps by showing a message to the user
      return;
    }
    setModalOpen(true);
    dispatchSubmission({ type: 'RESET' });
    dispatchSubmission({ type: 'START_AIR_SUBMISSION' });

    // Check if batchNumber is mandatory and is missing
    if (formValues.vaccineFundingType === 'NIP' && !formValues.batchNumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        batchNumber: 'Batch Number is required for NIP funded vaccines',
      }));
      return;
    }
    setModalState({ open: true, loading: true, success: false, message: '' });
    const individualEntity = individualIdentifierDetails?.data?.individualDetails;
    let episodes: Episode[] = [];
    logger.log('isMultipleVaccines', isMultipleVaccines);

    if (isMultipleVaccines) {
      // Construct episodes from the forms array for multiple vaccine records
      episodes = forms.map((form, index) => ({
        id: index + 1,
        ...(form.batchNumber && { vaccineBatch: form.batchNumber }),
        vaccineCode: form.vaccine,
        vaccineDose: form.dose, // Use form.dose or a calculated/default value
        ...(form.VaccineSerialNumber && {
          vaccineSerialNumber: form.VaccineSerialNumber,
        }),
        ...(form.vaccineFundingType && {
          vaccineFundingType: form.vaccineFundingType,
        }),
        ...(form.routeOfAdministration && {
          routeOfAdministration: form.routeOfAdministration,
        }),
      }));
    } else {
      // Construct a single episode based on default/singular form values if Record Multiple Vaccines is not checked
      episodes = [
        {
          id: 1,
          ...(formValues.batchNumber && {
            vaccineBatch: formValues.batchNumber,
          }),
          vaccineCode: formValues.vaccine,
          vaccineDose: formValues.dose, // Adjust accordingly
          ...(formValues.VaccineSerialNumber && {
            vaccineSerialNumber: formValues.VaccineSerialNumber,
          }),
          ...(formValues.vaccineFundingType && {
            vaccineFundingType: formValues.vaccineFundingType,
          }),
          ...(formValues.routeOfAdministration && {
            routeOfAdministration: formValues.routeOfAdministration,
          }),
          // dateOfService: immunisationDate ? immunisationDate.format("DDMMYYYY") : "",
        },
      ];
    }

    const buildPayload = () => {
      const individual = {
        ...(patientItemDetails?.medicareCardNumber && {
          medicareCard: {
            medicareCardNumber: patientItemDetails.medicareCardNumber,
            medicareIRN: patientItemDetails.medicareIRN,
          },
        }),
        personalDetails: {
          ...(patientItemDetails?.firstName && {
            firstName: patientItemDetails.firstName,
          }),
          ...(patientItemDetails?.lastName && {
            lastName: patientItemDetails.lastName,
          }),
          ...(patientItemDetails?.dateOfBirth && {
            dateOfBirth: patientItemDetails.dateOfBirth.split('-').reverse().join(''),
          }),
          ...(patientItemDetails?.gender && {
            gender: patientItemDetails.gender,
          }),
          ...(patientItemDetails?.onlyNameIndicator && {
            onlyNameIndicator: patientItemDetails.onlyNameIndicator,
          }),
        },
        address: {
          ...(patientItemDetails?.addressLineOne && {
            addressLineOne: patientItemDetails.addressLineOne,
          }),
          ...(patientItemDetails?.addressLineTwo && {
            addressLineTwo: patientItemDetails.addressLineTwo,
          }),
          ...(patientItemDetails?.locality && {
            locality: patientItemDetails.locality,
          }),
          ...(patientItemDetails?.postCode && {
            postCode: patientItemDetails.postCode,
          }),
        },
        ...(patientItemDetails?.isAboriginal && {
          atsiIndicator: 'Y',
        }),
        ...(patientItemDetails?.ihiNumber && {
          ihiNumber: patientItemDetails.ihiNumber,
        }),
        ...(acceptAndConfirm === 'Y'
          ? {
              acceptAndConfirm: acceptAndConfirm,
            }
          : {}),
      };

      const encounters = [
        {
          id: 1,
          ...(claimId && {
            claimSequenceNumber: '1',
            acceptAndConfirm: 'Y', // Assuming you always set this
          }), // Use the claimId from the state

          dateOfService: immunisationDate ? immunisationDate.format('DDMMYYYY') : '',
          ...(formValues.SchoolId && { schoolId: formValues.SchoolId }),
          ...(!isOverseas &&
            isOtherProvider &&
            formValues.otherProviderNumber && {
              immunisationProvider: {
                providerNumber: formValues.otherProviderNumber, // Assuming this is always set
                ...(formValues.OtherHpioNumber && {
                  hpioNumber: formValues.OtherHpioNumber,
                }),
                ...(formValues.OtherHpiiNumber && {
                  hpiiNumber: formValues.OtherHpiiNumber,
                }),
              },
            }),
          episodes,
          ...(isOverseas && {
            administeredOverseas: isOverseas,
            ...(formValues.countryCode && {
              countryCode: formValues.countryCode,
            }),
          }),
        },
      ];

      const vaccineDetails = {
        siteOfAdministration: formValues.siteOfAdministration,
        expiryDate: formValues.expiryDate,
        vaccineBrand: formValues.vaccineBrandName,
      };
      return {
        individualIdentifier: individualEntity?.individualIdentifier ?? '',
        individual,
        ...(claimId && { claimId }), // Use the claimId from the state
        informationProvider: {
          providerNumber: providerNumber, // Assuming this is always set
          ...(hpioNumber && { hpioNumber }),
          ...(hpiiNumber && { hpiiNumber }),
        },
        encounters,
        vaccineDetails,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
        patientID: patientItemDetails?.patientID,
        pharmacyID: pharmacyID,
        providerFirstName: providerFirstName,
        providerLastName: providerLastName,
        transactionUUID: transactionUUID, // Add this line
        ...(vaccinationAssessmentAnswers && {
          vaccinationAssessmentAnswers: vaccinationAssessmentAnswers,
        }),
      };
    };

    // Call the API
    const payload: any = buildPayload();
    logger.log('payload:', payload);

    try {
      const apiRecordEncounter = process.env.REACT_APP_RECORD_ENCOUNTER;
      if (!apiRecordEncounter) {
        throw new Error('REACT_APP_RECORD_ENCOUNTER is not defined');
      }
      const airResponse = await fetch(apiRecordEncounter, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!airResponse.ok) {
        throw new Error(`HTTP error! status: ${airResponse.status}`);
      }

      const airData: AIRRecordEncounterResponse = await airResponse.json();
      logger.log('Response:', airData);
      const airSuccess: boolean = handleApiResponse(airData, true);
      let errorMessage = 'Vaccination record submission failed';
      if (airData.data.errors) {
        // Handle case when errors are present
        errorMessage = Array.isArray(airData.data.errors)
          ? airData.data.errors.map((error: any) => `${error.code}: ${error.message}`).join(', ')
          : JSON.stringify(airData.data.errors);
      } else if (airData.data.claimDetails && airData.data.claimDetails.encounters) {
        // Handle case when errors need to be extracted from claimDetails
        errorMessage = airData.data.claimDetails.encounters
          .flatMap((encounter) => [
            encounter.information,
            ...(Array.isArray(encounter.episodes) ? encounter.episodes.map((episode) => episode.information) : []),
          ])
          .filter((info) => info && (info.status === 'WARNING' || info.status === 'INVALID'))
          .map((info) => `${info.code}: ${info.text}`)
          .filter((message) => message !== ': ')
          .join(', ');
      }
      dispatchSubmission({
        type: 'COMPLETE_AIR_SUBMISSION',
        success: airSuccess,
        message: airSuccess ? 'Vaccination record submitted successfully' : errorMessage,
      });
      setSubmissionStatus({
        submitted: true,
        success: airSuccess,
        message: airSuccess ? 'Vaccination record submitted successfully' : 'Error submitting to AIR',
      });
      const patientDateOfBirth = patientItemDetails?.dateOfBirth
        ? convertDateFormat(patientItemDetails.dateOfBirth)
        : '';
      let patientMedicareDvaNumber =
        patientItemDetails?.medicareCardNumber && patientItemDetails?.medicareIRN
          ? `${patientItemDetails.medicareCardNumber}${patientItemDetails.medicareIRN}`
          : '';

      if (needsMedicareDetails()) {
        patientMedicareDvaNumber = `${medicareDVANumber}${medicareIRN}`;
      }

      if (airSuccess) {
        const patientSummary = {
          firstName: patientItemDetails?.firstName || '',
          lastName: patientItemDetails?.lastName || '',
          dateOfBirth: patientItemDetails?.dateOfBirth || '',
          gender: patientItemDetails?.gender || '',
          addressLineOne: patientItemDetails?.addressLineOne || '',
          addressLineTwo: patientItemDetails?.addressLineTwo || '',
          locality: patientItemDetails?.locality || '',
          postCode: patientItemDetails?.postCode || '',
          encounterDate: immunisationDate ? immunisationDate.format('YYYY-MM-DD') : '',
          status: 'Completed',
          vaccineBrand: formValues.vaccineBrandName,
          providerFirstName: providerFirstName,
          providerLastName: providerLastName,
          providerNumber: providerNumber,
          claimId: claimId,
          deviceName: deviceName,
          siteOfAdministration: formValues.siteOfAdministration,
          transactionUUID: transactionUUID,
          ...(vaccinationAssessmentAnswers && { vaccinationAssessmentAnswers }),
        };

        setSubmittedPatientDetails(patientSummary);

        // Reset the form state
        setFormState({
          immunisationDate: null,
          formValues: initialFormState,
          expiryDate: null,
          isNipClaim: false,
          medicareDVANumber: '',
          medicareIRN: '',
          isOverseas: false,
          isOtherProvider: false,
          vaccinationAssessmentAnswers: null,
        });

        // Reset local state
        setFormValues(initialFormState);
        setExpiryDate(null);
        setTransactionUUID(uuidv4());
        setIsNipClaim(false);
        setMedicareDVANumber('');
        setMedicareIRN('');
        setIsOverseas(false);
        setIsOtherProvider(false);
        setVaccinationAssessmentAnswers(null);

        if (formValues.nipClaim) {
          setWasPpaSubmissionAttempted(true);
          dispatchSubmission({ type: 'START_PPA_SUBMISSION' });
          if (formValues.infectiousDisease === 'COVID-19' && formValues.vaccineFundingType === 'NIP') {
            const cvcpPayload: CVCPClaimPayload[] = [
              {
                programRegistrationId: CVCP || 0, // Provide the actual ID
                patientMedicareDVANumber: patientMedicareDvaNumber,
                patientGivenNames: patientItemDetails?.firstName ?? '',
                patientFamilyName: patientItemDetails?.lastName ?? '',
                dose: formValues.dose,
                patientDateOfBirth: patientDateOfBirth,
                dateOfVaccination: immunisationDate ? immunisationDate.format('YYYY-MM-DD') : '',
                patientConsentObtained: true,
                vaccinatorGivenNames: providerFirstName, // GET Pharmacist Name from Context
                vaccinatorFamilyName: providerLastName, // GET Pharmacist Name from Context
                vaccinatorAHPRA: providerAHPRA, // Change to actual value
                agreeToTermsOfService: 1,
              },
            ];
            logger.log('🚀 ~ handleSubmit ~ cvcpPayload:', cvcpPayload);
            const userDetails: UserDetails = {
              userId: userId || '',
              userKey: userKey || '',
              pharmacyId: pharmacyID,
              providerNumber: providerNumber,
            };
            const ppaResponse = await submitcovidVaxClaims(cvcpPayload, userDetails);
            ppaSuccess = handlePpaApiResponse(ppaResponse);
            if (airSuccess && (!formValues.nipClaim || ppaSuccess)) {
              setFormValues(initialFormState);
              setTransactionUUID(uuidv4()); // Generate new UUID for the next request
            }
          } else {
            const nipvipPayload: NipvipClaimPayload[] = [
              {
                programRegistrationId: NIPVIP || 0, // Provide the actual ID
                patientMedicareDvaNumber: patientMedicareDvaNumber,
                patientGivenName: patientItemDetails?.firstName ?? '',
                patientFamilyName: patientItemDetails?.lastName ?? '',
                patientDateOfBirth: patientDateOfBirth,
                dateOfService: immunisationDate ? immunisationDate.format('YYYY-MM-DD') : '',
                offSiteVaccination: 0, // Adjust based on actual value
                // vaccinationPremises: 1, // Adjust based on actual value
                airProviderNumber: providerNumber,
                // patientPostcode: patientItemDetails?.postCode ?? "",
                // patientLocality: patientItemDetails?.locality ?? "",
                brandName: formValues.vaccineBrandName, // Adjust based on actual value
                airCode: formValues.vaccine, // Adjust based on actual value
                vaccinatorAHPRA: providerAHPRA,
                vaccinatorFamilyName: providerLastName,
                vaccinatorGivenName: providerFirstName,
                vaccineBatchNumber: formValues.batchNumber,
                agreeToTermsOfService: 1,
              },
            ];
            logger.log('🚀 ~ handleSubmit ~ nipvipPayload:', nipvipPayload);
            const userDetails: UserDetails = {
              userId: userId || '',
              userKey: userKey || '',
              pharmacyId: pharmacyID,
              providerNumber: providerNumber,
            };

            const ppaResponse = await submitNipvipClaim(nipvipPayload, userDetails);

            ppaSuccess = handlePpaApiResponse(ppaResponse);

            setSubmissionStatus((prevStatus) => ({
              ...prevStatus,
              success: airSuccess && ppaSuccess,
              message: `AIR: ${airSuccess ? 'Success' : 'Failed'}, PPA: ${ppaSuccess ? 'Success' : 'Failed'}`,
            }));
            if (airSuccess && ppaSuccess) {
              setFormValues(initialFormState);
              setExpiryDate(null);
              setTransactionUUID(uuidv4()); // Generate new UUID for the next request
            } else if (airSuccess) {
              setFormValues(initialFormState);
              setExpiryDate(null);
              setTransactionUUID(uuidv4()); // Generate new UUID for the next request
            }
          }
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      dispatchSubmission({
        type: 'COMPLETE_AIR_SUBMISSION',
        success: false,
        message: `Error submitting form: ${(error as Error).message}`,
      });
    }
  };

  const submitNewCustomer = async () => {
    // NEW CUSTOMER HAS SO MANY GOTCHAS,
    if (!idToken) {
      console.error('No authentication token available');
      // Handle this error state, perhaps by showing a message to the user
      return;
    }
    setModalState({ open: true, loading: true, success: false, message: '' });
    logger.log('patientItemDetails', patientItemDetails);
    const individualEntity = individualIdentifierDetails?.data?.individualDetails;
    logger.log('Individual Entity:', individualEntity);
    let episodes: Episode[] = [];
    logger.log('isMultipleVaccines', isMultipleVaccines);
    if (isMultipleVaccines) {
      // Construct episodes from the forms array for multiple vaccine records
      logger.log('FORMS INSIDE ', forms);
      episodes = forms.map((form, index) => ({
        id: index + 1,
        ...(form.batchNumber && { vaccineBatch: form.batchNumber }),
        vaccineCode: form.vaccine,
        vaccineDose: form.dose, // Use form.dose or a calculated/default value
        ...(form.VaccineSerialNumber && {
          vaccineSerialNumber: form.VaccineSerialNumber,
        }),
        ...(form.vaccineFundingType && {
          vaccineFundingType: form.vaccineFundingType,
        }),
        ...(form.routeOfAdministration && {
          routeOfAdministration: form.routeOfAdministration,
        }),
        // dateOfService: form.immunisationDate ? form.immunisationDate.format("DDMMYYYY") : "",
        // Add more fields as required
      }));
    } else {
      // Construct a single episode based on default/singular form values if Record Multiple Vaccines is not checked
      episodes = [
        {
          id: 1,
          ...(formValues.batchNumber && {
            vaccineBatch: formValues.batchNumber,
          }),
          vaccineCode: formValues.vaccine,
          vaccineDose: formValues.dose, // Adjust accordingly
          ...(formValues.VaccineSerialNumber && {
            vaccineSerialNumber: formValues.VaccineSerialNumber,
          }),
          ...(formValues.vaccineFundingType && {
            vaccineFundingType: formValues.vaccineFundingType,
          }),
          ...(formValues.routeOfAdministration && {
            routeOfAdministration: formValues.routeOfAdministration,
          }),
          // dateOfService: immunisationDate ? immunisationDate.format("DDMMYYYY") : "",
        },
      ];
    }
    const buildPayload = () => {
      const individual = {
        ...(patientItemDetails?.medicareCardNumber && {
          medicareCard: {
            medicareCardNumber: patientItemDetails.medicareCardNumber,
            medicareIRN: patientItemDetails.medicareIRN,
          },
        }),
        personalDetails: {
          ...(patientItemDetails?.firstName && {
            firstName: patientItemDetails.firstName,
          }),
          ...(patientItemDetails?.lastName && {
            lastName: patientItemDetails.lastName,
          }),
          ...(patientItemDetails?.dateOfBirth && {
            dateOfBirth: patientItemDetails.dateOfBirth.split('-').reverse().join(''),
          }),
          ...(patientItemDetails?.gender && {
            gender: patientItemDetails.gender,
          }),
          ...(patientItemDetails?.onlyNameIndicator && {
            onlyNameIndicator: patientItemDetails.onlyNameIndicator,
          }),
        },
        address: {
          ...(patientItemDetails?.addressLineOne && {
            addressLineOne: patientItemDetails.addressLineOne,
          }),
          ...(patientItemDetails?.addressLineTwo && {
            addressLineTwo: patientItemDetails.addressLineTwo,
          }),
          ...(patientItemDetails?.locality && {
            locality: patientItemDetails.locality,
          }),
          ...(patientItemDetails?.postCode && {
            postCode: patientItemDetails.postCode,
          }),
        },
        ...(patientItemDetails?.isAboriginal && {
          atsiIndicator: 'Y',
        }),
        ...(patientItemDetails?.ihiNumber && {
          ihiNumber: patientItemDetails.ihiNumber,
        }),
        ...(acceptAndConfirm === 'Y'
          ? {
              acceptAndConfirm: acceptAndConfirm,
            }
          : {}),
      };

      const encounters = [
        {
          id: 1,
          dateOfService: immunisationDate ? immunisationDate.format('DDMMYYYY') : '',
          // ...(claimId && { claimId }), // Use the claimId from the state
          ...(!isOverseas &&
            isOtherProvider &&
            formValues.otherProviderNumber && {
              immunisationProvider: {
                providerNumber: formValues.otherProviderNumber, // Assuming this is always set
                ...(formValues.OtherHpioNumber && { hpioNumber }),
                ...(formValues.OtherHpiiNumber && { hpiiNumber }),
              },
            }),
          episodes,
          ...(isOverseas && {
            administeredOverseas: isOverseas,
            ...(formValues.countryCode && {
              countryCode: formValues.countryCode,
            }),
          }),
        },
      ];

      return {
        individualIdentifier: individualEntity?.individualIdentifier ?? '',
        individual,
        ...(claimId && { claimId }), // Use the claimId from the state
        informationProvider: {
          providerNumber: providerNumber, // Assuming this is always set
          ...(hpioNumber && { hpioNumber }),
          ...(hpiiNumber && { hpiiNumber }),
        },
        encounters,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
        patientID: patientItemDetails?.patientID,
        pharmacyID: pharmacyID,
        providerFirstName: providerFirstName,
        providerLastName: providerLastName,
        transactionUUID: transactionUUID, // Add this line
        ...(vaccinationAssessmentAnswers && {
          vaccinationAssessmentAnswers: vaccinationAssessmentAnswers,
        }),
      };
    };

    // Call the API
    const payload: any = buildPayload();
    logger.log('claimId:', formValues.claimId);
    logger.log('Payload:', payload);

    try {
      logger.log(payload);
      const apiRecordEncounter = process.env.REACT_APP_RECORD_ENCOUNTER;
      if (!apiRecordEncounter) {
        throw new Error('REACT_APP_RECORD_ENCOUNTER is not defined');
      }
      const response = await fetch(apiRecordEncounter, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: AIRRecordEncounterResponse = await response.json();
      logger.log('Response:', data);
      const submissionStatus = handleApiResponse(data);
      const patientDateOfBirth = patientItemDetails?.dateOfBirth
        ? convertDateFormat(patientItemDetails.dateOfBirth)
        : '';
      const patientMedicareDVANumber =
        patientItemDetails?.medicareCardNumber && patientItemDetails?.medicareIRN
          ? `${patientItemDetails.medicareCardNumber}${patientItemDetails.medicareIRN}`
          : '';
      if (submissionStatus) {
        if (formValues.nipClaim) {
          if (formValues.infectiousDisease === 'COVID-19' && formValues.vaccineFundingType === 'NIP') {
            const cvcpPayload: CVCPClaimPayload[] = [
              {
                programRegistrationId: CVCP || 0, // Provide the actual ID
                patientMedicareDVANumber: patientMedicareDVANumber,
                patientGivenNames: patientItemDetails?.firstName ?? '',
                patientFamilyName: patientItemDetails?.lastName ?? '',
                dose: formValues.dose,
                patientDateOfBirth: patientDateOfBirth,
                dateOfVaccination: immunisationDate ? immunisationDate.format('YYYY-MM-DD') : '',
                patientConsentObtained: true,
                vaccinatorGivenNames: providerFirstName, // GET Pharmacist Name from Context
                vaccinatorFamilyName: providerLastName, // GET Pharmacist Name from Context
                vaccinatorAHPRA: providerAHPRA, // Change to actual value
                agreeToTermsOfService: 1,
              },
            ];
            logger.log('🚀 ~ handleSubmit ~ cvcpPayload:', cvcpPayload);
            const userDetails: UserDetails = {
              userId: userId || '',
              userKey: userKey || '',
              pharmacyId: pharmacyID,
              providerNumber: providerNumber,
            };
            const ppaResponse = await submitcovidVaxClaims(cvcpPayload, userDetails);
            handlePpaApiResponse(ppaResponse);
            setFormValues(initialFormState);
          } else {
            const nipvipPayload: NipvipClaimPayload[] = [
              {
                programRegistrationId: NIPVIP || 0, // Provide the actual ID
                patientMedicareDvaNumber: patientMedicareDVANumber,
                patientGivenName: patientItemDetails?.firstName ?? '',
                patientFamilyName: patientItemDetails?.lastName ?? '',
                patientDateOfBirth: patientDateOfBirth,
                dateOfService: immunisationDate ? immunisationDate.format('YYYY-MM-DD') : '',
                offSiteVaccination: 0, // Adjust based on actual value
                // vaccinationPremises: 1, // Adjust based on actual value
                airProviderNumber: providerNumber,
                // patientPostcode: patientItemDetails?.postCode ?? "",
                // patientLocality: patientItemDetails?.locality ?? "",
                brandName: formValues.vaccineBrandName, // Adjust based on actual value
                airCode: formValues.vaccine, // Adjust based on actual value
                vaccinatorAHPRA: providerAHPRA,
                vaccinatorFamilyName: providerLastName,
                vaccinatorGivenName: providerFirstName,
                vaccineBatchNumber: formValues.batchNumber,
                agreeToTermsOfService: 1,
              },
            ];
            const userDetails: UserDetails = {
              userId: userId || '',
              userKey: userKey || '',
              pharmacyId: pharmacyID,
              providerNumber: providerNumber,
            };
            logger.log('🚀 ~ handleSubmit ~ nipvipPayload:', nipvipPayload);

            const ppaResponse = await submitNipvipClaim(nipvipPayload, userDetails);
            handlePpaApiResponse(ppaResponse);
            setFormValues(initialFormState);
          }
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      dispatchSubmission({
        type: 'COMPLETE_AIR_SUBMISSION',
        success: false,
        message: `Error submitting form: ${(error as Error).message}`,
      });
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: `Error submitting form: ${(error as Error).message}`,
      });

      console.error('Error submitting form:', error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    if (submissionState.air.success && (!formValues.nipClaim || submissionState.ppa.success)) {
      setShowPatientDetailsModal(true);
    }
  };

  const handlePpaApiResponse = (response: any): boolean => {
    logger.log('🚀 ~ handlePpaApiResponse ~ response:', response);

    // Extract the actual data from the response
    const responseData = response.data;

    // Initialize message containers
    let successMessages: string[] = [];
    let errorMessages: string[] = [];

    // Handle success responses
    if (responseData.successResponse && responseData.successResponse.length > 0) {
      successMessages = responseData.successResponse.map((res: any) =>
        res.value.submitted ? 'NIP Claim submitted successfully' : 'NIP Claim not submitted'
      );
    }

    // Handle failure responses
    if (responseData.failureResponse && responseData.failureResponse.length > 0) {
      errorMessages = responseData.failureResponse.map((res: any) => {
        const validationErrors = res.value.validationErrors || [];
        const errorDetails =
          validationErrors.length > 0 ? validationErrors.join(', ') : res.value.error || res.value.errors; // <-- Adjusted to handle both scenarios
        return `${errorDetails} (position: ${res.value.positionInCollection})`;
      });
    }

    // Combine success and error messages
    const combinedMessages = [...successMessages, ...errorMessages].join(', ');
    logger.log('🚀 ~ handlePpaApiResponse ~ combinedMessages:', combinedMessages);
    const ppaSuccess = successMessages.length > 0 && errorMessages.length === 0;

    dispatchSubmission({
      type: 'COMPLETE_PPA_SUBMISSION',
      success: ppaSuccess,
      message: combinedMessages || 'No specific error message provided',
    });
    // Update PPA submission status
    setPpaSubmissionStatus({
      submitted: true,
      success: responseData.successResponse && responseData.successResponse.length > 0,
      message: combinedMessages,
      loading: false,
    });

    // Update modal state
    setModalState({
      ...modalState,
      open: true,
      loading: false,
      success: responseData.successResponse && responseData.successResponse.length > 0,
      message: combinedMessages || 'PPA submission completed with some issues.',
    });
    return ppaSuccess;
  };

  interface StatusIndicator {
    key: keyof any; // Use 'keyof IndividualDetails' to ensure keys exist on the IndividualDetails interface
    label: string;
    message: string;
  }

  const statusIndicators: StatusIndicator[] = [
    {
      key: 'indigenousStatus',
      label: 'Indigenous Status',
      message: 'This individual is identified as Indigenous.',
    },
    {
      key: 'additionalVaccineIndicator',
      label: 'Additional Vaccine Indicator',
      message: 'Eligible for additional vaccines.',
    },
    {
      key: 'medContraindicationIndicator',
      label: 'Medical Contraindication Indicator',
      message: 'Has medical contraindications.',
    },
    {
      key: 'naturalImmunityIndicator',
      label: 'Natural Immunity Indicator',
      message: 'Identified to have natural immunity.',
    },
    {
      key: 'vaccineTrialIndicator',
      label: 'Vaccine Trial Indicator',
      message: 'Participated in a vaccine trial.',
    },
    {
      key: 'actionRequiredIndicator',
      label: 'Action Required Indicator',
      message: 'Action required.',
    },
  ];

  const areAllFormsValid = (): boolean => {
    return forms.every((form) => {
      // Example validation for a few fields, ensure to check for undefined or use optional chaining
      const vaccineValid = form.vaccine?.trim() !== '';
      const vaccineSerialNumberValid = form.VaccineSerialNumber?.trim() !== '';
      const routeOfAdministrationValid = form.routeOfAdministration?.trim() !== '';
      const batchNumberValid = form.batchNumber?.trim() !== '';

      // Add more validation checks as necessary
      return vaccineValid && vaccineSerialNumberValid && routeOfAdministrationValid && batchNumberValid;
    });
  };

  const getTrueIndicatorMessages = (individualDetails: any | null): string[] => {
    if (!individualDetails) return [];

    const messages: string[] = [];
    statusIndicators.forEach((indicator) => {
      if (individualDetails[indicator.key]) {
        // TypeScript knows `indicator.key` is a valid key of IndividualDetails
        messages.push(indicator.message);
      }
    });

    return messages;
  };

  useEffect(() => {
    // This effect listens for changes to acceptAndConfirm state.
    // When the user confirms the action, the state is set to "Y",
    // triggering this effect and calling submitNewCustomer.
    if (acceptAndConfirm === 'Y') {
      submitNewCustomer();
      setAcceptAndConfirm(''); // Reset to default state or another appropriate value
    }
  }, [acceptAndConfirm]); // Depend on acceptAndConfirm state

  // Dialog Actions
  <DialogActions>
    <Button onClick={() => setShowConfirmDialog(false)} color="primary">
      No
    </Button>
    <Button
      onClick={() => {
        setAcceptAndConfirm('Y'); // Set state to indicate confirmation
        setShowConfirmDialog(false); // Close dialog
      }}
      color="primary"
      autoFocus
    >
      Yes
    </Button>
  </DialogActions>;

  const trueIndicatorMessages = getTrueIndicatorMessages(individualIdentifierDetails?.data?.individualDetails);
  // In Parent Component
  const handleFormUpdate = (index: number, updatedFormValues: FormValue) => {
    const newForms = forms.map((form, i) => {
      if (i === index) {
        return updatedFormValues;
      }
      return form;
    });
    logger.log('newForms', newForms);
    setForms(newForms);
  };

  interface Answers {
    [key: string]: string; // Adjust this if the actual structure is different
  }

  const [hasConsiderationAnswers, setHasConsiderationAnswers] = useState(false);

  const handleAIDComplete = (answers: VaccinationScreeningInput) => {
    // Convert boolean values to 'yes'/'no' strings if needed
    const stringAnswers: Answers = Object.entries(answers).reduce((acc, [key, value]) => {
      acc[key] = value ? 'yes' : 'no';
      return acc;
    }, {} as Answers);

    logger.log('Converted string responses:');
    logger.log(JSON.stringify(stringAnswers, null, 2));
    // Check if any answer requires consideration
    const requiresConsideration = Object.entries(answers).some(([key, value]) => {
      // Add logic here to determine which answers require consideration
      // For example, if any 'yes' answer except for 'planningTravel' requires consideration:
      return value === true && key !== 'planningTravel';
    });

    setHasConsiderationAnswers(requiresConsideration);

    setVaccinationAssessmentAnswers(answers); // Store the original boolean answers

    // Update the parent state
    setFormState({
      immunisationDate,
      formValues,
      expiryDate,
      isNipClaim,
      medicareDVANumber,
      medicareIRN,
      isOverseas,
      isOtherProvider,
      vaccinationAssessmentAnswers: answers,
    });

    handleAIDClose();
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return <Typography color="error">Error loading authentication token: {idTokenError}</Typography>;
  }

  const needsMedicareDetails = () => {
    const patientMedicareDVANumber =
      patientItemDetails?.medicareCardNumber && patientItemDetails?.medicareIRN
        ? `${patientItemDetails.medicareCardNumber}${patientItemDetails.medicareIRN}`
        : '';

    return formValues.vaccineFundingType === 'NIP' && !patientMedicareDVANumber;
  };
  const validateMedicareNumber = (medicareNumber: string) => {
    if (medicareNumber.length !== 10) {
      return false;
    }

    const weights = [1, 3, 7, 9, 1, 3, 7, 9];
    let sum = 0;

    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(medicareNumber.charAt(i), 10) * weights[i];
    }

    const checkDigit = parseInt(medicareNumber.charAt(8), 10);
    const isIssueNumberValid = parseInt(medicareNumber.charAt(9), 10) !== 0;
    const calculatedCheckDigit = sum % 10;

    return checkDigit === calculatedCheckDigit && isIssueNumberValid;
  };

  const validateMedicareIRN = (irn: string): boolean => {
    return /^[1-9]$/.test(irn);
  };

  const NIPDeclaration = () => (
    <div>
      <Typography>By clicking Submit you acknowledge and agree that:</Typography>
      <ul>
        <li>
          By submitting a claim on behalf of the pharmacy, you agree that you are authorised to submit this claim and
          bind the pharmacy to the Pharmacy Programs Administrator (PPA) General Terms and Conditions (General Terms)
          and the National Immunisation Program Vaccinations in Pharmacy (NIPVIP) Program Rules, applicable as at the
          date of the service.
        </li>
        <li>All information provided in this claim is complete and correct.</li>
        <li>
          You have permission to pass on the details of the vaccination recipient and vaccinator included in the claim
          to the PPA and the Australian Government, as required under the General Terms and NIPVIP Program Rules.
        </li>
        <li>
          The service included in this claim was supplied in accordance with the General Terms and the NIPVIP Program
          Rules, including those related to patient eligibility.
        </li>
        <li>Documentation in relation to this claim is available for audit by the PPA.</li>
        <li>
          The service included in this claim has been correctly reported to the Australian Immunisation Register (AIR).
        </li>
        <li>The patient has not been charged any fee in relation to the service.</li>
        <li>
          The vaccine administered is funded under the National Immunisation Program and has been administered to an
          individual aged 5 years or older.
        </li>
        <li>The administration of each specific service/vaccine has not been claimed previously.</li>
      </ul>
    </div>
  );

  const COVIDDeclaration = () => (
    <div>
      <Typography>Declaration</Typography>
      <Typography>By clicking Submit you acknowledge and agree that:</Typography>
      <ol type="a">
        <li>
          By submitting a claim on behalf of the pharmacy, you agree that you are authorised to submit this claim and
          bind the pharmacy to the Pharmacy Program Administrator General Terms and Conditions (General Terms) and the
          Program Rules, applicable as at the date of the Service.
        </li>
        <li>All information provided in this claim is complete and correct</li>
        <li>
          You have permission to pass on the details of the vaccination recipient included in the claim to the Pharmacy
          Programs Administrator and the Australian Government, as required under the General Terms and Program Rules.
        </li>
        <li>
          All Services included in this claim were supplied in accordance with the General Terms and the Program Rules,
          including those related to patient eligibility.
        </li>
        <li>Documentation in relation to this claim is available for audit by the Pharmacy Programs Administrator.</li>
      </ol>
    </div>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Paper>
            <Box component="form" onSubmit={handleSubmit} noValidate p={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      label="Date of Service"
                      format="DD/MM/YYYY"
                      value={immunisationDate}
                      onChange={(newValue) => setImmunisationDate(newValue)}
                      disableFuture
                      onError={(newError) => setDateError(newError)}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        border: 1,
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center', // Ensures the button is centered
                        width: '100%', // Ensures the box takes the full width
                      }}
                    >
                      <Button
                        onClick={handleAIDOpen}
                        color="primary"
                        endIcon={
                          vaccinationAssessmentAnswers ? (
                            hasConsiderationAnswers ? (
                              <AlertCircle size={20} color="#FFA500" />
                            ) : (
                              <CheckCircleIcon style={{ color: '#4CAF50' }} />
                            )
                          ) : null
                        }
                      >
                        Vaccination Assessment
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </LocalizationProvider>
              {isMultipleVaccines ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {forms.map((form, index) => (
                    <VaccineRecordForm
                      key={index}
                      index={index}
                      formValues={form}
                      setFormValues={(newFormValues) => handleFormUpdate(index, newFormValues)}
                      removeForm={() => removeForm(index)}
                      handleFormUpdate={handleFormUpdate}
                      errorInfo={form.errorInfo}
                    />
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'left', mt: 1 }}>
                    <Button
                      variant="outlined"
                      startIcon={<AddOutlinedIcon />}
                      onClick={addForm}
                      sx={{
                        display: 'flex',
                        gap: 0,
                        mt: 1,
                        marginBottom: 0,
                        fontWeight: 'bold',
                        mb: 1,
                      }}
                    >
                      Add Vaccine
                    </Button>
                  </Box>
                </LocalizationProvider>
              ) : (
                <div>
                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        marginBottom: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {diseases.map((disease) => (
                        <Button
                          key={disease}
                          variant={
                            formValues.infectiousDisease === disease || (disease === 'Others' && showDiseaseDropdown)
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() => handleDiseaseSelection(disease)}
                          sx={{
                            textTransform: 'none',
                            minWidth: '100px',
                            flex: 1,
                            justifyContent: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          {disease}
                        </Button>
                      ))}
                    </Box>
                    {showDiseaseDropdown && (
                      <FormControl fullWidth margin="normal" variant="outlined" size="small">
                        <InputLabel id="other-diseases-label">Other Diseases</InputLabel>
                        <Select
                          labelId="other-diseases-label"
                          name="infectiousDisease"
                          value={formValues.infectiousDisease}
                          onChange={handleOtherDiseaseChange}
                          label="Other Diseases"
                        >
                          {otherDiseases.map((disease) => (
                            <MenuItem key={disease.value} value={disease.value}>
                              {disease.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <InputLabel id="funding-type-label">Vaccine Type</InputLabel>
                    <Select
                      labelId="funding-type-label"
                      id="vaccineFundingType"
                      name="vaccineFundingType"
                      value={formValues.vaccineFundingType}
                      onChange={handleChange}
                      label="Vaccine Type"
                    >
                      {vaccineFundingTypes.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {needsMedicareDetails() && (
                    <>
                      <TextField
                        label="Medicare Number"
                        type="text"
                        name="medicareNumber"
                        size="small"
                        value={medicareDVANumber}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setMedicareDVANumber(newValue);
                          if (newValue.length > 0) {
                            const isValid = newValue.length === 10 && validateMedicareNumber(newValue);
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareNumber: isValid ? null : 'Invalid Medicare number',
                            }));
                          } else if (formValues.vaccineFundingType === 'NIP') {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareNumber: 'Medicare Number is required for NIP funded vaccines',
                            }));
                          } else {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareNumber: null,
                            }));
                          }
                        }}
                        onBlur={() => {
                          if (medicareDVANumber.trim() === '' && formValues.vaccineFundingType === 'NIP') {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareNumber: 'Medicare Number is required for NIP funded vaccines',
                            }));
                          } else if (medicareDVANumber.length !== 10) {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareNumber: 'Medicare number must be 10 digits',
                            }));
                          }
                        }}
                        fullWidth
                        margin="normal"
                        required={formValues.vaccineFundingType === 'NIP'}
                        error={formErrors.medicareNumber !== null}
                        helperText={formErrors.medicareNumber}
                      />
                      <TextField
                        label="Medicare IRN"
                        type="text"
                        name="medicareIRN"
                        size="small"
                        value={medicareIRN}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue === '' || validateMedicareIRN(newValue)) {
                            setMedicareIRN(newValue);
                            if (newValue === '' && formValues.vaccineFundingType === 'NIP') {
                              setFormErrors((prev) => ({
                                ...prev,
                                medicareIRN: 'Medicare IRN is required for NIP funded vaccines',
                              }));
                            } else {
                              setFormErrors((prev) => ({
                                ...prev,
                                medicareIRN: null,
                              }));
                            }
                          } else {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareIRN: 'IRN must be a single digit from 1 to 9',
                            }));
                          }
                        }}
                        onBlur={() => {
                          if (medicareIRN.trim() === '' && formValues.vaccineFundingType === 'NIP') {
                            setFormErrors((prev) => ({
                              ...prev,
                              medicareIRN: 'Medicare IRN is required for NIP funded vaccines',
                            }));
                          }
                        }}
                        fullWidth
                        margin="normal"
                        required={formValues.vaccineFundingType === 'NIP'}
                        error={formErrors.medicareIRN !== null}
                        helperText={formErrors.medicareIRN}
                        inputProps={{ maxLength: 1 }}
                      />
                    </>
                  )}

                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <InputLabel id="vaccine-label">Vaccines</InputLabel>
                    <Select
                      labelId="vaccine-label"
                      name="vaccine"
                      value={formValues.vaccine}
                      onChange={handleVaccineChange}
                      label="Vaccine label"
                    >
                      {filteredVaccines.map((vaccine) => (
                        <MenuItem
                          key={vaccine.value}
                          value={vaccine.value}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {vaccine.label}
                          {vaccine.isEligibleForNIP && formValues.vaccineFundingType === 'NIP' && (
                            <Box
                              component="span"
                              sx={{
                                ml: 1,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: '50%',
                                width: 24,
                                height: 24,
                                fontSize: '0.75rem',
                              }}
                            >
                              NIP
                            </Box>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <InputLabel id="route-admin-label">Route of Administration</InputLabel>
                    <Select
                      labelId="route-admin-label"
                      id="routeOfAdministration"
                      name="routeOfAdministration"
                      value={formValues.routeOfAdministration}
                      onChange={handleChange}
                      label="Route of Administration"
                    >
                      {routeOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <InputLabel id="site-admin-label">Site of Administration</InputLabel>
                    <Select
                      labelId="site-admin-label"
                      id="siteOfAdministration"
                      name="siteOfAdministration"
                      value={formValues.siteOfAdministration}
                      onChange={handleChange}
                      label="Site of Administration"
                    >
                      {siteOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Batch Number"
                    type="text"
                    name="batchNumber"
                    size="small"
                    value={formValues.batchNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={formErrors.batchNumber !== null}
                    helperText={formErrors.batchNumber}
                  />
                  <FormControl fullWidth margin="normal" variant="outlined" size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateField
                        label="Expiry Date"
                        size="small"
                        format="MM/YYYY"
                        value={expiryDate}
                        onChange={(newValue: Dayjs | null) => {
                          setExpiryDate(newValue);
                          setFormValues((prevValues: FormValues) => ({
                            ...prevValues,
                            expiryDate: newValue ? newValue.format('MM/YYYY') : '',
                          }));
                        }}
                        fullWidth
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <TextField
                    label="Vaccine Dose"
                    type="text"
                    name="dose"
                    size="small"
                    value={formValues.dose}
                    required
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue === '' || /^\d+$/.test(newValue)) {
                        handleChange({
                          target: {
                            name: 'dose',
                            value: newValue,
                          },
                        } as React.ChangeEvent<HTMLInputElement>);
                        // Clear the error if the input is valid
                        setFormErrors((prev) => ({
                          ...prev,
                          dose: null,
                        }));
                      } else {
                        // Set an error message if non-numeric input is detected
                        setFormErrors((prev) => ({
                          ...prev,
                          dose: 'Please enter a numeric value for the vaccine dose',
                        }));
                      }
                    }}
                    error={formErrors.dose !== null}
                    helperText={formErrors.dose || 'Enter the number of dose. Example 1, 2, 3 etc.'}
                    fullWidth
                    margin="normal"
                    sx={{
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                        fontWeight: 'bold',
                      },
                    }}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                  <TextField
                    label="Vaccine Serial Number"
                    type="text"
                    name="VaccineSerialNumber"
                    size="small"
                    value={formValues.VaccineSerialNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </div>
              )}

              <TextField
                label="School Id"
                type="text"
                name="SchoolId"
                size="small"
                value={formValues.SchoolId}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={<Switch checked={isOverseas} onChange={handleOverseasChange} />}
                  label="Administered Overseas"
                  labelPlacement="end"
                />
              </FormGroup>
              {isOverseas && (
                <Autocomplete
                  options={countryCodes}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Country" margin="normal" size="small" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                    setFormValues({
                      ...formValues,
                      countryCode: newValue?.code ?? '',
                    });
                  }}
                  fullWidth
                />
              )}
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={<Switch checked={isOtherProvider} onChange={hanldeOtherProvider} />}
                  label="Administered By Other Provider"
                  labelPlacement="end"
                />
                {isOtherProvider && (
                  <Grid container spacing={0}>
                    <TextField
                      label="Other Provider Number"
                      type="text"
                      name="otherProviderNumber"
                      size="small"
                      value={formValues.otherProviderNumber}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <Grid>
                      <TextField
                        label="Other Provider HPIO Number"
                        type="text"
                        name="OtherHpioNumber"
                        size="small"
                        value={formValues.OtherHpioNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Other Provider HPIINumber"
                        type="text"
                        name="OtherHpiiNumber"
                        size="small"
                        value={formValues.OtherHpiiNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                )}
              </FormGroup>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox checked={formValues.cmiProvided} onChange={handleCheckboxChange} name="cmiProvided" />
                  }
                  label="The Patient has consented to receive the vaccination"
                />
              </FormGroup>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues.deemedEligible}
                      onChange={handleCheckboxChange}
                      name="deemedEligible"
                    />
                  }
                  label="The patient has been evaluated and deemed eligible for vaccination"
                />
              </FormGroup>

              {isNipClaim && (
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Checkbox checked={formValues.nipClaim} onChange={handleCheckboxChange} name="nipClaim" />}
                    label="Submit for CVCP / NIP Claim via PPA"
                  />
                </FormGroup>
              )}
              {formValues.nipClaim && (
                <div style={{ marginTop: '16px' }}>
                  {formValues.infectiousDisease === 'COVID-19' ? <COVIDDeclaration /> : <NIPDeclaration />}
                </div>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitButtonDisabled()}
              >
                Submit With AIR
              </Button>
              {submissionStatus.submitted && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={submissionState.air.success ? 'success' : 'error'} style={{ marginBottom: '8px' }}>
                    <AlertTitle>AIR Submission</AlertTitle>
                    {submissionState.air.message}
                  </Alert>
                  {wasPpaSubmissionAttempted && (
                    <Alert severity={submissionState.ppa.success ? 'success' : 'error'} style={{ marginBottom: '8px' }}>
                      <AlertTitle>PPA Submission</AlertTitle>
                      {submissionState.ppa.message}
                    </Alert>
                  )}
                </Box>
              )}
              <SubmissionStatusModal
                open={modalOpen}
                onClose={handleModalClose}
                submissionState={submissionState}
                wasPpaSubmissionAttempted={wasPpaSubmissionAttempted}
                vaccinationAssessmentAnswers={vaccinationAssessmentAnswers}
              />
              <PatientDetailsModal
                open={showPatientDetailsModal}
                onClose={() => setShowPatientDetailsModal(false)}
                patientDetails={submittedPatientDetails}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper>
            <Box p={2} sx={{ mt: -3 }}>
              <Typography variant="h6">Vaccinations Due</Typography>
              {vaxHistory &&
              vaxHistory.data &&
              vaxHistory.data.immunisationDetails &&
              vaxHistory.data.immunisationDetails.dueList &&
              vaxHistory.data.immunisationDetails.dueList.length > 0 ? (
                <PatientVaccinationsDueList vaxHistory={vaxHistory} />
              ) : (
                <Typography>There are no vaccinations due for this individual</Typography>
              )}
            </Box>
          </Paper>
          <Paper>
            <Box p={2} sx={{ mt: 3 }}>
              <Typography variant="h6">Alerts</Typography>
              {trueIndicatorMessages.length > 0 ? (
                trueIndicatorMessages.map((message, index) => (
                  <Alert
                    key={index}
                    variant="filled"
                    severity="info"
                    sx={{
                      mb: 2,
                      backgroundColor: '#ab003c',
                    }}
                  >
                    {message}
                  </Alert>
                ))
              ) : (
                <Typography>No alerts found.</Typography>
              )}
            </Box>
          </Paper>
          <Paper>
            <Box p={2} sx={{ mt: 3 }}>
              <Typography variant="h6">Immunisation History</Typography>
              {individualDetails && (
                <PatientVaccinationHistory
                  patientIdentifier={patientIdentifier}
                  vaxHistory={vaxHistory}
                  individualDetails={individualDetails}
                  refreshIndividualDetails={() => {}}
                  columnsToShow={['date', 'vaccine', 'vaccineBrand']}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{'Confirm Individual Details'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            AIR-W-1004: Individual was not found. Please either correct the individual details or confirm and accept
            individual details are correct.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              setAcceptAndConfirm('Y');
              setShowConfirmDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAIDOpen} onClose={handleAIDClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Vaccination Assessment
          <IconButton
            aria-label="close"
            onClick={handleAIDClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <VaccinationAID onComplete={handleAIDComplete} initialAnswers={vaccinationAssessmentAnswers || undefined} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PatientVaccination;
