import { BOOKING_STATUS } from '../../interfaces/IBooking';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { GENDERS } from '../../constants';
import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import AutocompleteCustomer from '../AutocompleteCustomer';
import DatePicker from '../DatePicker';
import GenderDropdown from '../GenderDropdown';
import { EAppointmentModalMode } from './AppointmentModal';
import SelectService from '../../pages/apps/Calendar/Forms/SelectService';
import SelectDate from './SelectDate';
import moment from 'moment-timezone';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ICustomer } from '../../interfaces';
import { extractName } from '../../utils';
import {
  UpdateBookingsInput,
  UpdatePharmacyAvailabilityInput,
} from '../../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import AuthContext from '../../contexts/AuthContext';
import SelectAddress from './SelectAddress';
import { useBookings } from '../../hooks/useBookings';
import { useSnackbar } from '../../contexts/SnackbarContext';
import ReportSnackbar from '../ReportSnackbar';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectRoomReservationConnected from '../SelectRoomReservationConnected';
import { IAvailability } from '../../interfaces/healthcare-reservation/IAvailability';
import { useAvailabilities } from '../../hooks/useAvailabilities';
import _ from 'lodash';
import { logger } from '../../utils/logger';
import { formatToAvailability } from '../../utils/graphql-layers/reservations';
import { IBooking } from 'interfaces/healthcare-reservation/IBooking';

interface IAppointmentFormProps {
  appointment: IBooking | null;
  mode: EAppointmentModalMode;
  onClickCancel?: (bookingId: string) => void;
  onClose?: (bookingId?: string) => void;
}

const NoResult = () => {
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Typography>No Result to show</Typography>
    </Box>
  );
};

const emptyValueValidator = (value: string | undefined) => {
  if (typeof value === 'string' && value.trim().length > 0) {
    return true;
  }

  return false;
};

const appointmentFormSchema = yup.object({
  patient: yup.object({
    phoneNumber: yup
      .string()
      .test('empty-value', 'Empty value are not allowed', emptyValueValidator)
      .required('Phone number is required'),
    addressLineOne: yup
      .string()
      .test('empty-value', 'Empty value are not allowed', emptyValueValidator)
      .required('Address is required'),
    emailAddress: yup
      .string()
      .test('empty-value', 'Empty value are not allowed', emptyValueValidator)
      .required('Email address is required'),
    firstName: yup
      .string()
      .test('empty-value', 'Empty value are not allowed', emptyValueValidator)
      .required('Fullname is required'),
  }),
});

const AppointmentForm = ({ appointment, mode, onClickCancel, onClose }: IAppointmentFormProps) => {
  const [partialField, setPartialField] = useState<{
    name: 'InjectionBrandName' | 'VaccineBrandName';
    value: string;
  } | null>(null);
  const { pharmacyID } = useContext(AuthContext);
  const { openSnackbar } = useSnackbar();
  const { updateBooking, loadingUpdateBooking, errorUpdateBooking, updatedBooking } = useBookings();
  const [selectedAvailability, setSelectedavailability] = useState<IAvailability | null>(null);
  const [currentAvailability, setCurrentAvailability] = useState<IAvailability | null>(null);
  const {
    getAvailability,
    loadingGetAvailability,
    errorGetAvailability,
    updateAvailability,
    errorUpdateAvailability,
    loadingUpdateAvailability,
  } = useAvailabilities();

  // success listener
  useEffect(() => {
    if (updatedBooking) {
      openSnackbar({
        message: 'Appointment updated!',
        severity: 'success',
        action: null,
      });

      if (typeof onClose === 'function') onClose(updatedBooking.updateBookings!.bookingID);
    }
  }, [updatedBooking]);

  // error listener
  useEffect(() => {
    if (errorUpdateBooking) {
      openSnackbar({
        message: 'Sorry, an error occured trying to updating appointment',
        severity: 'error',
        action: <ReportSnackbar error={errorUpdateBooking} />,
      });

      if (typeof onClose === 'function') onClose(undefined);
    }
  }, [errorUpdateBooking]);

  const methods = useForm<IBooking>({
    defaultValues: appointment || {
      bookingID: '',
      serviceId: '',
      status: BOOKING_STATUS.CONFIRMED,
      timeslot: '',
      patientID: '',
      pharmacyID: '',
      availabilityID: '',
      durationInSeconds: 1800,
      InjectionBrandName: '',
      VaccineBrandName: '',
      bookingDate: '',
      createdDate: '',
      patient: {
        patientID: '',
        phoneNumber: '',
        pharmacyID: '',
        emailAddress: '',
        addressLineOne: '',
        dateOfBirth: '',
        firstName: '',
        lastName: '',
        gender: GENDERS[GENDERS.length - 1].value,
        createdDate: '',
      },
      room: null,
    },
    resolver: yupResolver(appointmentFormSchema),
  });

  // populate partial field
  useEffect(() => {
    if (appointment) {
      const isVaccine =
        appointment.VaccineBrandName !== '' &&
        appointment.VaccineBrandName !== undefined &&
        appointment.VaccineBrandName !== null;

      const isInjection =
        appointment.InjectionBrandName !== '' &&
        appointment.InjectionBrandName !== undefined &&
        appointment.InjectionBrandName !== null;

      if (isVaccine) {
        setPartialField({
          name: 'VaccineBrandName',
          value: appointment.VaccineBrandName || '',
        });
      }

      if (isInjection) {
        setPartialField({
          name: 'InjectionBrandName',
          value: appointment.InjectionBrandName || '',
        });
      }

      // availability
      getAvailability({
        availabilityID: appointment.availabilityID,
        onCompleted(data) {
          setSelectedavailability(data);
          setCurrentAvailability(data);
        },
      });
    }
  }, [appointment]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isSubmitting },
    watch,
    clearErrors,
  } = methods;

  const onSubmitEvent = async (data: IBooking) => {
    // logger.log('Current booking ', appointment);
    // logger.log('Updated booking ', data);
    // logger.log('Partial field ', partialField);
    try {
      // update booking
      const { payment, ...restData } = data // exclude the payment because it causing a bug, we can include payment again later on after the updateBookings resolver is fixed
      const bookingPayload: UpdateBookingsInput = {
        ...restData,
        [partialField!.name]: partialField?.value || null,
        BookingMonth: moment.months(moment(data.timeslot).tz('Australia/Sydney').month()),
        updatedDate: moment().tz('Australia/Sydney').toISOString(),
        pharmacyID,
      };
      logger.log(bookingPayload, 'bookingPayload');

      const { errors } = await updateBooking({
        variables: {
          input: bookingPayload,
        },
      });

      if (errors && errors.length) {
        const error = new Error('Failed updating appointments');
        error.name = 'ErrorUpdateException';
        throw error;
      }

      const isAvailabilityChanged = appointment?.availabilityID !== data.availabilityID;

      if (isAvailabilityChanged) {
        // update previous availability
        const prevAvailability = currentAvailability!;

        const availableSlotIsGreaterThanSlotInStore =
          prevAvailability.maxSlotsAvailable > prevAvailability.maxSlotsInStore;

        const maxSlotsAvailable = availableSlotIsGreaterThanSlotInStore
          ? prevAvailability.maxSlotsInStore
          : prevAvailability.maxSlotsAvailable + 1;

        const roomsForPreviousAvailability = prevAvailability.rooms.map((room) => {
          if (room.roomID === appointment?.room?.roomID) {
            return {
              ...room,
              isAvailable: true,
            };
          }

          return room;
        });

        const updatePreviousPayload: UpdatePharmacyAvailabilityInput = {
          availabilityID: prevAvailability.availabilityID,
          maxSlotsAvailable,
          rooms: roomsForPreviousAvailability,
          isAvailable: roomsForPreviousAvailability.some((room) => room.isAvailable !== false),
        };

        logger.log(updatePreviousPayload, 'updatePreviousPayload');

        // update previous availability operation
        const { data: responseUpdatePrevAvailability, errors: errorsUpdatePrevAvailability } = await updateAvailability(
          {
            variables: {
              input: updatePreviousPayload,
            },
          }
        );

        // update current selected availability
        const roomsForSelectedAvailability = selectedAvailability?.rooms?.map((room) => {
          if (room?.roomID === data.room?.roomID) {
            return {
              ...room,
              isAvailable: false,
            };
          }

          return room;
        });

        const uppdateSelectedAvailabilityPayload: UpdatePharmacyAvailabilityInput = {
          availabilityID: data.availabilityID,
          maxSlotsAvailable:
            selectedAvailability!.maxSlotsAvailable <= 0 ? 0 : selectedAvailability!.maxSlotsAvailable - 1,
          rooms: roomsForSelectedAvailability,
          isAvailable: roomsForSelectedAvailability?.some((room) => room?.isAvailable !== false),
        };

        logger.log(uppdateSelectedAvailabilityPayload, 'uppdateSelectedAvailabilityPayload');

        const { data: responseUpdateSelectedAvailability, errors: errorsUpdateSelectedAvailability } =
          await updateAvailability({
            variables: {
              input: uppdateSelectedAvailabilityPayload,
            },
          });

        if (!responseUpdatePrevAvailability?.updatePharmacyAvailability) {
          const error = new Error(
            `Error updating previous availability ${JSON.stringify(errorsUpdatePrevAvailability)}`
          );
          error.name = 'ErrorUpdatePreviousAvailabilityException';
          throw error;
        }

        if (!responseUpdateSelectedAvailability?.updatePharmacyAvailability) {
          const error = new Error(
            `Error updating selected availability ${JSON.stringify(errorsUpdateSelectedAvailability)}`
          );
          error.name = 'ErrorUpdateSelectedAvailabilityException';
          throw error;
        }
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const handleOnSelectCustomer = (customer: ICustomer) => {
    const { dateOfBirth, email, phoneNumber, firstName, lastName, gender, addressLineOne, customerID } = customer;

    setValue('patient.addressLineOne', addressLineOne);
    setValue('patient.gender', gender.trim().length === 0 ? 'Prefer not to say' : gender);
    setValue('patient.dateOfBirth', dateOfBirth);
    setValue('patient.emailAddress', email);
    setValue('patient.phoneNumber', phoneNumber);
    setValue('patient.firstName', firstName);
    setValue('patient.lastName', lastName);
    setValue('patient.patientID', customerID);
  };

  const handleOnClearCustomer = () => {
    setValue('patient.addressLineOne', '');
    setValue('patient.gender', '');
    setValue('patient.dateOfBirth', '');
    setValue('patient.emailAddress', '');
    setValue('patient.phoneNumber', '');
    setValue('patient.firstName', '');
    setValue('patient.lastName', '');
    setValue('patient.patientID', '');
  };

  const handleOnChangeCustomer = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    const { firstName, lastName } = extractName(value);
    setValue('patient.firstName', firstName);
    setValue('patient.lastName', lastName);

    if (value.trim().length > 0) {
      clearErrors('patient.firstName');
    }
  };

  const handleOnSelectPlace = (place: google.maps.places.PlaceResult | null) => {
    if (place && place.formatted_address) {
      setValue('patient.addressLineOne', place.formatted_address);
    }
  };

  const handleCancelAppointment = async (bookingID: string) => {
    try {
      const { errors } = await updateBooking({
        variables: {
          input: {
            bookingID,
            status: BOOKING_STATUS.CANCELED_BY_PHARMACY,
            updatedDate: moment().tz('Australia/Sydney').toISOString(),
          },
        },
      });

      if (errors && errors.length) {
        const error = new Error('Failed updating appointments');
        error.name = 'ErrorUpdateException';
        throw error;
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const nothingToUpdate = _.isEqual(appointment, watch());
  const isLoading = loadingUpdateBooking || loadingGetAvailability || loadingUpdateAvailability;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitEvent)}>
        <Card
          sx={{
            overflow: 'visible', // for dropdown time picker
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="h5" gutterBottom flex={1}>
                Appointment Details
              </Typography>
              {/* {isEventFromClickDate && isUnavailable && (
                <Alert severity="warning">
                  <UnavailableAlertText />
                </Alert>
              )} */}
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectService
                  defaultValue={appointment?.serviceId}
                  onSelect={(service) => {
                    setValue('serviceId', service!.serviceId);
                    setValue('durationInSeconds', service!.durationInSeconds);

                    const isVaccine =
                      service?.serviceName?.includes('vaccination') || service?.serviceName?.includes('Vaccination');

                    if (isVaccine) {
                      setPartialField((prev) => ({
                        ...prev!,
                        name: 'VaccineBrandName',
                      }));
                    } else {
                      setPartialField((prev) => ({
                        ...prev!,
                        name: 'InjectionBrandName',
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(event) => {
                    setPartialField((prev) => ({
                      ...prev!,
                      value: event.target.value,
                    }));
                  }}
                  value={partialField?.value || ''}
                  fullWidth
                  label={`Service Product Name`}
                  placeholder={`eg: 'Pfizzer' for vaccination service...`}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDate
                  defaultValue={appointment}
                  onSelectDate={(availability) => {
                    setValue('availabilityID', availability.availabilityID);
                    setValue('bookingDate', moment(availability.timeslot).tz('Australia/Sydney').format('YYYY-MM-DD'));
                    setValue('timeslot', availability.timeslot);

                    setSelectedavailability(formatToAvailability(availability));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="room"
                  control={control}
                  rules={{
                    required: 'Please choose the room',
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <SelectRoomReservationConnected
                        availability={selectedAvailability}
                        defaultRoom={field.value}
                        error={!!fieldState.error}
                        errorMessage={fieldState.error?.message}
                        onSelectedRoom={(room) => {
                          logger.log(room);
                          if (room) {
                            setValue('room', {
                              isAvailable: room.isAvailable as boolean,
                              roomID: room.roomID as string,
                              roomName: room.roomName as string,
                            });
                            clearErrors('room');
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginTop: '20px' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Patient Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="patient.firstName"
                  control={control}
                  render={({ fieldState }) => {
                    return (
                      <AutocompleteCustomer
                        fieldsToShow={['dateOfBirth', 'addressLineOne', 'phoneNumber']}
                        label="Full Name"
                        defaultValue={`${getValues().patient?.firstName} ${getValues().patient?.lastName ?? ''}`.trim()}
                        onChange={handleOnChangeCustomer}
                        CustomNoResult={<NoResult />}
                        onSelect={handleOnSelectCustomer}
                        onClear={handleOnClearCustomer}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="patient.emailAddress"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                        fullWidth
                        type="email"
                        label="Email Address"
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="patient.dateOfBirth"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, value, onChange, ...rest } = field;
                    const valueData = typeof value === 'string' && value.length === 0 ? moment() : moment(value);
                    return (
                      <DatePicker
                        {...rest}
                        label="Date of Birth"
                        value={moment(valueData).isValid() ? valueData : moment()}
                        onChange={(value) => {
                          if (value) setValue('patient.dateOfBirth', value.format('YYYY-MM-DD'));
                        }}
                        disableFuture
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="patient.gender"
                  control={control}
                  render={({ field }) => {
                    return (
                      <GenderDropdown
                        defaultValue={getValues().patient?.gender || 'Prefer not to say'}
                        onSelect={(gender) => {
                          setValue('patient.gender', gender);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="patient.addressLineOne"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, onChange, ...rest } = field;
                    return (
                      <SelectAddress
                        apiKey="AIzaSyAv2vANIvhPQU6wIOtn54KvfiQ8zFfCYBA"
                        onPlaceSelected={handleOnSelectPlace}
                        size="medium"
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        {...rest}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="patient.phoneNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Contact Number"
                      placeholder="Type a phone number here..."
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box mt={3} display="flex" justifyContent="space-between">
          {mode === EAppointmentModalMode.EDIT && (
            <Button
              color="error"
              onClick={() => {
                if (typeof onClickCancel === 'function' && appointment) {
                  onClickCancel(appointment.bookingID);
                }

                if (!onClickCancel && appointment) {
                  handleCancelAppointment(appointment.bookingID);
                }
              }}
              variant="contained"
            >
              Cancel Appointment
            </Button>
          )}
          <Box display="flex" gap="1rem" alignItems="center">
            <Button
              onClick={() => {
                if (typeof onClose === 'function') {
                  onClose(appointment?.bookingID);
                }
              }}
              disabled={isLoading || isSubmitting}
              size="large"
              variant="outlined"
            >
              Close
            </Button>
            <Button
              disabled={isLoading || isSubmitting || nothingToUpdate}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AppointmentForm;
