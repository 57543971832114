// import { IBooking } from 'interfaces/IBooking';
import { IBooking, IBookingPatient } from 'interfaces/healthcare-reservation/IBooking';
import {
  Bookings,
  PatientAppointments,
  PharmacyAvailability,
  Room,
  Service,
} from '../../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { IService, IServicePrice } from '../../interfaces/IService';
import { IServiceRoom } from '../../interfaces/IServiceRoom';
import { IAvailability, IAvailabilityRoom } from '../../interfaces/healthcare-reservation/IAvailability';
import { IRoom } from '../../interfaces/healthcare-reservation/IRoom';
import { BOOKING_STATUS } from 'interfaces/IBooking';
import moment from 'moment';
import { uuid } from 'utils/util';

interface IGraphqlAvailabilityRooms extends NonNullable<PharmacyAvailability['rooms']> {}
interface IGraphqlServiceRooms extends NonNullable<Service['rooms']> {}
interface IGraphqlServicePrice extends NonNullable<Service['price']> {}

const formatToAvailabilityRoom = (room: IGraphqlAvailabilityRooms[number] | null): IAvailabilityRoom | null => {
  if (!room) {
    return room;
  }

  return {
    roomID: room.roomID || null,
    roomName: room.roomName || null,
    isAvailable: room.isAvailable || false,
  };
};

export const formatToAvailability = (availability: PharmacyAvailability | null): IAvailability | null => {
  if (!availability) {
    return availability;
  }

  const rooms = availability.rooms
    ?.map(formatToAvailabilityRoom)
    .filter((room): room is IAvailabilityRoom => room !== null);

  return {
    availabilityID: availability.availabilityID,
    durationInSeconds: availability.durationInSeconds,
    isAvailable: availability.isAvailable || false,
    maxSlotsAvailable: availability.maxSlotsAvailable,
    maxSlotsInStore: availability.maxSlotsInStore,
    pharmacyID: availability.pharmacyID,
    serviceId: availability.serviceId,
    serviceName: availability.serviceName,
    timeslot: availability.timeslot,
    rooms: rooms || [],
  };
};

export const formatToAserviceRoom = (
  room: NonNullable<IGraphqlServiceRooms[number]> | IRoom | null
): IServiceRoom | null => {
  if (!room) {
    return room;
  }

  return {
    createdDate: room.createdDate || '',
    isAvailable: room.isAvailable || false,
    pharmacyID: room.pharmacyID,
    roomID: room.roomID,
    roomName: room.roomName || '',
    description: room.description || '',
  };
};

export const formatToAservicePrice = (price: IGraphqlServicePrice | null): IServicePrice => {
  return {
    ammount: price?.ammount || 100,
    currency: price?.currency || 'aud',
    includeGst: price?.includeGst || false,
  };
};

export const formatToAservice = (service: Service | null): IService | null => {
  if (!service) {
    return service;
  }

  return {
    serviceId: service.serviceId,
    categoryID: service.categoryID || '',
    durationInSeconds: service.durationInSeconds,
    isActive: service.isActive,
    pharmacyID: service.pharmacyID,
    pharmacyName: service.pharmacyName || '',
    rooms: service.rooms?.map(formatToAserviceRoom).filter((room): room is IServiceRoom => room !== null) || [],
    serviceName: service.serviceName || '',
    serviceScheduleID: service.serviceScheduleID || '',
    price: formatToAservicePrice(service.price || null),
    allowPayment: service.allowPayment || false,
  };
};

const mapRoomSchedule = (schedule: Room['schedule']): IRoom['schedule'] => {
  if (!schedule) {
    return {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
  }

  const originalSchedule = typeof schedule === 'string' ? JSON.parse(schedule) : schedule;

  const scheduleData = Array.isArray(originalSchedule) ? originalSchedule[0] : originalSchedule;

  return {
    monday: scheduleData.monday || false,
    tuesday: scheduleData.tuesday || false,
    wednesday: scheduleData.wednesday || false,
    thursday: scheduleData.thursday || false,
    friday: scheduleData.friday || false,
    saturday: scheduleData.saturday || false,
    sunday: scheduleData.sunday || false,
  };
};

export const formatToARoom = (room: Room | null): IRoom | null => {
  if (!room) {
    return room;
  }

  return {
    roomID: room.roomID,
    pharmacyID: room.pharmacyID!,
    roomName: room.roomName || '',
    createdDate: room.createdDate || '',
    isAvailable: room.isAvailable || false,
    description: room.description || '',
    isActive: room.isActive || false,
    snoozed: room.snoozed || false,
    snoozeTime: room.snoozeTime || null,
    schedule: mapRoomSchedule(room.schedule),
    unavailableDates: room.unavailableDates?.filter((date): date is string => date !== null) || [],
  };
};

export const formatToABooking = (booking: Bookings | null): IBooking | null => {
  if (!booking) {
    return booking;
  }

  return {
    bookingID: booking.bookingID,
    availabilityID: booking.availabilityID,
    bookingDate: booking.bookingDate || moment(booking.timeslot).tz('Australia/Sydney').format('YYYY-MM-DD'),
    durationInSeconds: booking.durationInSeconds,
    InjectionBrandName: booking.InjectionBrandName || '',
    patient: {
      addressLineOne: booking.patient?.addressLineOne || null,
      dateOfBirth: booking.patient?.dateOfBirth,
      emailAddress: booking.patient?.emailAddress || null,
      firstName: booking.patient?.firstName || null,
      gender: booking.patient?.gender || null,
      lastName: booking.patient?.lastName || null,
      locality: booking.patient?.locality || null,
      patientID: booking.patient?.patientID || null,
      pharmacyID: booking.patient?.pharmacyID || null,
      phoneNumber: booking.patient?.phoneNumber || null,
      postCode: booking.patient?.postCode || null,
      state: booking.patient?.state || null,
    },
    patientID: booking.patientID,
    payment: {
      channel: booking.payment?.channel || null,
      status: booking.payment?.status || null,
      token: booking.payment?.token || null,
      clientSecret: booking.payment?.token || null,
    },
    pharmacyID: booking.pharmacyID,
    room: {
      isAvailable: booking.room?.isAvailable || false,
      roomID: booking.room?.roomID || null,
      roomName: booking.room?.roomName || null,
    },
    serviceId: booking.serviceId,
    status: (booking.status as BOOKING_STATUS) || BOOKING_STATUS.CREATED,
    timeslot: booking.timeslot,
    VaccineBrandName: booking.VaccineBrandName || null,
    IsAssessmentCompleted: booking.IsAssessmentCompleted || false,
  };
};

export const formatToABookingPatient = (patient: PatientAppointments | null): IBookingPatient | null => {
  if (!patient) {
    return patient;
  }

  return {
    addressLineOne: patient.addressLineOne || null,
    dateOfBirth: patient.dateOfBirth || null,
    emailAddress: patient.emailAddress || null,
    firstName: patient.firstName || null,
    gender: patient.gender || null,
    lastName: patient.lastName || null,
    locality: patient.locality || null,
    patientID: patient.patientID || uuid(),
    pharmacyID: patient.pharmacyID || null,
    phoneNumber: patient.phoneNumber || null,
    postCode: patient.postCode || null,
    state: patient.state || null,
  };
};
